import { createSlice } from "@reduxjs/toolkit";
import {
  getUserLoadboardTimeGraphData,
  getManagerAnalyticsData,
  getUserPerformanceGraphData,
  getUserAnalyticsData,
  getUserQueries,
  getUserQueriesFilters,
  getManagerLoadboardTimeGraphData,
  getDispatchLeaderBoard,
} from "../async/getAnalyticsData";
import {
  TAnalyticsData,
  TAnalyticsDispatchLeaderBoard,
  TAnalyticsLoadboardTimeGraph,
  TAnalyticsPerformanceGraph,
  TAnalyticsPeriod,
  TAnalyticsQueriesFilters,
  TAnalyticsQuery,
  UserRole,
} from "@/types";
import { getUserOrganizationMembersList } from "../async/getMyTeamInfo";
import { getAllMembersSelected } from "@/utils/usersSelect";

interface IAnalyticsState {
  userType: UserRole | null;
  statsLoading: boolean;
  queriesLoading: boolean;
  queriesFiltersLoading: boolean;
  loadboardTimeGraphLoading: boolean;
  dispatchLeadeBoardLoading: boolean;
  performanceGraphLoading: boolean;
  statsPeriod: TAnalyticsPeriod;
  queriesPeriod: TAnalyticsPeriod;
  performancePeriod: TAnalyticsPeriod;
  managerQueriesPeriod: TAnalyticsPeriod;
  managerQueriesParams: {
    sortOrder: string;
    searchQuery: string;
  };
  queriesParams: {
    origin: string;
    destination: string;
    entity: string;
    sortOrder: string;
    searchQuery: string;
  };
  queriesPagination: {
    offset: number;
    pageNumber: number;
    pageSize: number;
    totalPages: number;
    totalElements: number;
  };
  phoneData: TAnalyticsData;
  emailData: TAnalyticsData;
  avgPostToContactTimeData: TAnalyticsData;
  avgProductiveTimeData: TAnalyticsData;
  avgRateData: TAnalyticsData;
  queries: TAnalyticsQuery[];
  managerQueries: TAnalyticsQuery[];
  queriesFilters: TAnalyticsQueriesFilters;
  loadboardTimeGraphData: TAnalyticsLoadboardTimeGraph[];
  performanceGraphData: TAnalyticsPerformanceGraph;
  dispatchLeaderBoardData: TAnalyticsDispatchLeaderBoard[];
  selectedUsers: string[];
}

const initialData: TAnalyticsData = {
  total: "0",
  percentage: "0%",
  up: true,
  rpm: "0",
  comparison_with_the_past: "0",
};

const initialState: IAnalyticsState = {
  userType: null,
  statsLoading: false,
  queriesLoading: false,
  queriesFiltersLoading: false,
  loadboardTimeGraphLoading: false,
  performanceGraphLoading: false,
  dispatchLeadeBoardLoading: false,
  statsPeriod: "year",
  queriesPeriod: "year",
  performancePeriod: "year",
  managerQueriesPeriod: "year",
  selectedUsers: [],
  managerQueriesParams: {
    sortOrder: "",
    searchQuery: "",
  },
  queriesParams: {
    origin: "",
    destination: "",
    entity: "",
    sortOrder: "",
    searchQuery: "",
  },
  queriesPagination: {
    offset: 0,
    pageNumber: 0,
    pageSize: 10,
    totalPages: 0,
    totalElements: 0,
  },
  phoneData: initialData,
  emailData: initialData,
  avgPostToContactTimeData: initialData,
  avgProductiveTimeData: initialData,
  avgRateData: initialData,
  queries: [],
  managerQueries: [],
  loadboardTimeGraphData: [],
  performanceGraphData: {
    totalTime: 0,
    performance_list: [],
  },
  queriesFilters: {
    origin: [],
    destination: [],
    entity: [],
  },
  dispatchLeaderBoardData: [],
};

const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    setStatsPeriod: (state, action) => {
      state.statsPeriod = action.payload;
    },
    setQueriesPeriod: (state, action) => {
      state.queriesPeriod = action.payload;
    },
    setManagerQueriesPeriod: (state, action) => {
      state.managerQueriesPeriod = action.payload;
    },
    setPerformancePeriod: (state, action) => {
      state.performancePeriod = action.payload;
    },
    setQueriesParams: (state, action) => {
      state.queriesParams = action.payload;
    },
    setManagerQueriesParams: (state, action) => {
      state.managerQueriesParams = action.payload;
    },
    setQueriesPagination: (state, action) => {
      state.queriesPagination = action.payload;
    },
    setUserType: (state, action) => {
      state.userType = action.payload;
    },
    setSelectedUsers: (state, action) => {
      state.selectedUsers = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserAnalyticsData.pending, (state) => {
      state.statsLoading = true;
    });
    builder.addCase(getUserAnalyticsData.fulfilled, (state, action) => {
      state.statsLoading = false;
      state.phoneData = action.payload.phoneData || state.phoneData;
      state.emailData = action.payload.emailData || state.emailData;
      state.avgPostToContactTimeData =
        action.payload.avgPostToContactTimeData ||
        state.avgPostToContactTimeData;
      state.avgProductiveTimeData =
        action.payload.avgProductiveTimeData || state.avgProductiveTimeData;
      state.avgRateData = action.payload.avgRateData || state.avgRateData;
    });
    builder.addCase(getUserAnalyticsData.rejected, (state) => {
      state.statsLoading = false;
    });

    builder.addCase(getManagerAnalyticsData.pending, (state) => {
      state.statsLoading = true;
    });
    builder.addCase(getManagerAnalyticsData.fulfilled, (state, action) => {
      state.statsLoading = false;
      state.phoneData = action.payload.phoneData || state.phoneData;
      state.emailData = action.payload.emailData || state.emailData;
      state.avgPostToContactTimeData =
        action.payload.avgPostToContactTimeData ||
        state.avgPostToContactTimeData;
      state.avgProductiveTimeData =
        action.payload.avgProductiveTimeData || state.avgProductiveTimeData;
      state.avgRateData = action.payload.avgRateData || state.avgRateData;
    });
    builder.addCase(getManagerAnalyticsData.rejected, (state) => {
      state.statsLoading = false;
    });

    builder.addCase(getUserQueriesFilters.pending, (state) => {
      state.queriesFiltersLoading = true;
    });
    builder.addCase(getUserQueriesFilters.fulfilled, (state, action) => {
      state.queriesFiltersLoading = false;
      state.queriesFilters = {
        origin: action.payload.origin || [],
        destination: action.payload.destination || [],
        entity: action.payload.entity || [],
      };
    });
    builder.addCase(getUserQueriesFilters.rejected, (state) => {
      state.queriesFiltersLoading = false;
    });

    builder.addCase(getUserQueries.pending, (state) => {
      state.queriesLoading = true;
    });
    builder.addCase(getUserQueries.fulfilled, (state, action) => {
      state.queriesLoading = false;
      state.queries = action.payload.content || state.queries;
      state.queriesPagination = {
        offset: state.queriesPagination.offset,
        pageNumber: action.payload.number,
        pageSize: action.payload.size,
        totalPages: action.payload.totalPages,
        totalElements: action.payload.totalElements,
      };
    });
    builder.addCase(getUserQueries.rejected, (state) => {
      state.queriesLoading = false;
    });

    builder.addCase(getDispatchLeaderBoard.pending, (state) => {
      state.dispatchLeadeBoardLoading = true;
    });
    builder.addCase(getDispatchLeaderBoard.fulfilled, (state, action) => {
      state.dispatchLeadeBoardLoading = false;
      state.dispatchLeaderBoardData = action.payload;
    });
    builder.addCase(getDispatchLeaderBoard.rejected, (state) => {
      state.dispatchLeadeBoardLoading = false;
    });
    builder.addCase(getUserLoadboardTimeGraphData.pending, (state) => {
      state.loadboardTimeGraphLoading = true;
    });
    builder.addCase(
      getUserLoadboardTimeGraphData.fulfilled,
      (state, action) => {
        state.loadboardTimeGraphLoading = false;
        state.loadboardTimeGraphData = action.payload;
      }
    );
    builder.addCase(getUserLoadboardTimeGraphData.rejected, (state) => {
      state.loadboardTimeGraphLoading = false;
    });

    builder.addCase(getManagerLoadboardTimeGraphData.pending, (state) => {
      state.loadboardTimeGraphLoading = true;
    });
    builder.addCase(
      getManagerLoadboardTimeGraphData.fulfilled,
      (state, action) => {
        state.loadboardTimeGraphLoading = false;
        state.loadboardTimeGraphData = action.payload;
      }
    );
    builder.addCase(getManagerLoadboardTimeGraphData.rejected, (state) => {
      state.loadboardTimeGraphLoading = false;
    });

    builder.addCase(getUserPerformanceGraphData.pending, (state) => {
      state.performanceGraphLoading = true;
    });
    builder.addCase(getUserPerformanceGraphData.fulfilled, (state, action) => {
      state.performanceGraphLoading = false;
      state.performanceGraphData = action.payload;
    });
    builder.addCase(getUserPerformanceGraphData.rejected, (state) => {
      state.performanceGraphLoading = false;
    });

    builder.addCase(
      getUserOrganizationMembersList.fulfilled,
      (state, action) => {
        state.selectedUsers = getAllMembersSelected(action.payload);
      }
    );
  },
});

export const {
  setStatsPeriod,
  setQueriesPeriod,
  setManagerQueriesPeriod,
  setPerformancePeriod,
  setQueriesParams,
  setManagerQueriesParams,
  setQueriesPagination,
  setUserType,
  setSelectedUsers,
} = analyticsSlice.actions;

export default analyticsSlice.reducer;
