import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthState, InitialState } from "@/types/index";
import { PERSIST_STORE_NAME } from "@/config/app.config";
import { setUserOrganisationName } from "../async/getMyTeamInfo";
import { sendUserInvitationToken } from "../async/getAuthData";

const initialState = {
  loading: false,
  value: {
    isAuth: false,
    email: "",
    userId: -1,
    accessToken: "",
    refreshToken: "",
    extensionToken: "",
    features: [],
    limitTempl: 0,
    limitEmails: 0,
    emails: 10,
    createdAt: "",
    trialDays: 1,
    accountVerified: true,
    billingPlan: "",
    organizationName: "",
    organizationId: 0,
    role: "USER",
  } as AuthState,
  invitationToken: "",
} as InitialState;

export const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.value.accessToken = action.payload;
    },
    setRefreshToken: (state, action: PayloadAction<string>) => {
      state.value.refreshToken = action.payload;
    },
    setAuthData: (state, action: PayloadAction<AuthState>) => {
      state.value = action.payload;
    },
    logOut: (state) => {
      state.value = initialState.value;
      localStorage.removeItem(PERSIST_STORE_NAME);
    },
    logIn: (state, action: PayloadAction<AuthState>) => {
      return {
        loading: false,
        value: action.payload,
        invitationToken: state.invitationToken,
      };
    },
    setInvitationToken: (state, action: PayloadAction<string>) => {
      state.invitationToken = action.payload;
    },
    removeInvitationToken: (state) => {
      state.invitationToken = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setUserOrganisationName.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(setUserOrganisationName.fulfilled, (state, action) => {
      state.value.organizationId = action.payload.id;
      state.value.organizationName = action.payload.name;
      state.loading = false;
    });
    builder.addCase(setUserOrganisationName.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(sendUserInvitationToken.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(sendUserInvitationToken.fulfilled, (state) => {
      state.loading = false;
      state.invitationToken = "";
    });
    builder.addCase(sendUserInvitationToken.rejected, (state) => {
      state.loading = false;
      state.invitationToken = "";
    });
  },
});

export const {
  logOut,
  logIn,
  setAuthData,
  setAccessToken,
  setRefreshToken,
  setInvitationToken,
  removeInvitationToken,
} = auth.actions;

export default auth.reducer;
