import { Key, useEffect, useMemo } from "react";
import { ManagerAnalytics } from "@/components/analytics/ManagerAnalytics";
import { UserAnalytics } from "@/components/analytics/UserAnalytics";
import { Tab, Tabs } from "@nextui-org/react";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { LockedView } from "@/components/analytics/components/LockedView";
import { setUserType } from "@/redux/slices/analytics-slice";
import { getUserOrganizationMembersList } from "@/redux/async/getMyTeamInfo";
import { TAnalyticsPeriod } from "@/types";

export const Analytics = () => {
  const dispatch = useAppDispatch();
  const userRole = useAppSelector((state) => state.authReducer.value.role);
  const features = useAppSelector((state) => state.authReducer.value.features);
  const userType = useAppSelector((state) => state.analyticsReducer.userType);
  const period: TAnalyticsPeriod = useAppSelector(
    (state) => state.analyticsReducer.statsPeriod
  );

  const isAnalyticsAvailable = features.includes("analytics");

  const handleUserTypeChange = (value: Key) => {
    dispatch(setUserType(value));
  };

  useEffect(() => {
    dispatch(getUserOrganizationMembersList());
  }, [dispatch]);

  const isManager = userType
    ? userType === "MANAGER"
    : userRole
    ? userRole === "MANAGER"
    : false;

  const periodTerm = useMemo(() => {
    let options = {};

    switch (period) {
      case "day":
      case "week":
        options = {
          year: "numeric",
          month: "long",
          day: "numeric",
        };
        break;
      case "month":
        options = {
          year: "numeric",
          month: "long",
        };
        break;
      case "year":
        options = {
          year: "numeric",
        };
        break;
    }

    return new Date().toLocaleDateString("us-US", options);
  }, [period]);

  if (!features.includes("analytics")) {
    return <LockedView />;
  }

  return (
    <>
      {userRole === "MANAGER" && (
        <Tabs
          size="sm"
          radius="lg"
          classNames={{
            base: "z-50 absolute top-5",
            tab: "text-sm",
            panel: "p-0",
          }}
          onSelectionChange={handleUserTypeChange}
          selectedKey={userType || userRole}
        >
          <Tab key="MANAGER" title="Manager" />
          <Tab key="USER" title="User" />
        </Tabs>
      )}
      <div className="relative grid grid-cols-2 gap-3 mt-4">
        {!isAnalyticsAvailable && <LockedView />}
        <div className="flex gap-4 items-center col-span-2 h-8">
          <h1 className="font-medium text-xl">Analytics</h1>
          <h2 className="font-normal text-xl">
            {periodTerm} {period === "year" && "year"}
          </h2>
        </div>
        {isManager ? <ManagerAnalytics /> : <UserAnalytics />}
      </div>
    </>
  );
};
