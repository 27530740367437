import { Button } from "@nextui-org/button";
import { Input } from "@nextui-org/input";

import EditorWrapper from "@/components/editor";
import { Template } from "@/types";
import { defaultValue } from "./default-value";
import { mergetags_list } from "@/components/editor/config";

const TemplateViewer = ({
  template,
  onChange,
  errorMessage,
  onLoad,
}: {
  template: Template;
  onLoad?: any;
  onChange: Function;
  errorMessage?: any;
}) => {
  const loadDefault = () => {
    onChange({
      ...template,
      template: defaultValue.body,
      subject: defaultValue.subject,
    });
  };

  const handleChange = (e: any) => {
    onChange({ ...template, [e.target.name]: e.target.value || "" });
  };

  return (
    <>
      <div className="grid grid-cols-3 mb-4">
        <Input
          labelPlacement="outside"
          variant="bordered"
          className="relative"
          type="text"
          id="name"
          name="name"
          placeholder="Template Name"
          label="Template Name"
          value={template.name}
          onChange={handleChange}
          classNames={{ label: "text-base text-gray-900 font-semibold" }}
          validationState={errorMessage["name"] !== "" ? "invalid" : "valid"}
          errorMessage={errorMessage["name"]}
        />
      </div>

      <div className="grid grid-cols-3 gap-4 divide-x">
        <div className="col-span-2">
          <div className="flex items-end">
            <Input
              value={template.subject}
              onChange={handleChange}
              labelPlacement="outside"
              variant="bordered"
              className="relative mb-4"
              type="text"
              id="subject"
              name="subject"
              placeholder="Subject"
              label="Subject"
              classNames={{
                inputWrapper: "pr-0",
                label: "text-base text-gray-900 font-semibold",
              }}
              validationState={
                errorMessage["subject"] !== "" ? "invalid" : "valid"
              }
              errorMessage={errorMessage["subject"]}
            />
            <Button
              variant="solid"
              disableAnimation
              onPress={loadDefault}
              className="rounded-l-none rounded-r-lg data-[pressed=true]:scale-100 mb-4"
            >
              Default Value
            </Button>
          </div>
          <label className="block text-base text-gray-900 font-semibold mb-1.5">
            Body
          </label>
          <EditorWrapper
            value={template.template}
            onChange={(val: string) => onChange({ ...template, template: val })}
            onLoad={onLoad}
          />
        </div>

        <div className="pl-2 ml-2">
          <h2 className="my-5 text-lg font-semibold text-gray-900 dark:text-white">
            Allowed Variables
          </h2>
          <ul className="max-w-md space-y-4 text-gray-500  dark:text-gray-400">
            {mergetags_list.map((tag) => (
              <li key={tag.value}>
                {"{{" + tag.value + "}}"} : <b>{tag.title}</b>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default TemplateViewer;
