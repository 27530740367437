import { FC } from "react";
import { PercentageStatus } from "@/components/percentage-status";
import { TooltipData, TooltipModel } from "@/types";

interface Props {
  tooltipModel: TooltipModel | null;
  data: TooltipData;
  withShift?: boolean;
  withArrow?: boolean;
}

export const ChartTooltip: FC<Props> = ({
  tooltipModel,
  data,
  withShift,
  withArrow,
}) => {
  if (!tooltipModel) {
    return null;
  }

  const tooltipStyles: any = {
    left: `${tooltipModel.caretX + (withShift ? 8 : 0)}px`,
    top: `${tooltipModel.caretY + (withShift ? 20 : 0)}px`,
  };

  const times = data.times?.[tooltipModel.datasetIndex][tooltipModel.dataIndex];
  const labels =
    data.labels?.[tooltipModel.datasetIndex][tooltipModel.dataIndex];
  const emails =
    data.emails?.[tooltipModel.datasetIndex][tooltipModel.dataIndex];
  const phones =
    data.calls?.[tooltipModel.datasetIndex][tooltipModel.dataIndex];

  return (
    <div
      style={tooltipStyles}
      className="z-50 flex flex-col p-2 bg-white rounded-xl absolute drop-shadow w-fit min-w-[100px]"
    >
      {withArrow && (
        <div className=" absolute top-1/2 -translate-y-1/2 -left-[10px] triangle"></div>
      )}
      {labels && <p className="text-gray-950 text-sm">{labels.value}</p>}
      <div className="flex gap-2 items-center">
        <div className="w-2 h-2 rounded-full bg-[#1e3a7c]"></div>
        <p className="text-gray-950 text-sm">{times.value}</p>
        {times.percentage?.toString() === "N/A" ? (
          <></>
        ) : (
          <PercentageStatus value={times.percentage} isUp={times.up} />
        )}
      </div>

      {emails && (
        <div className="flex gap-2 items-center">
          <div className="w-2 h-2 rounded-full bg-[#1848B5]"></div>

          <p className="text-gray-950 text-sm">{emails?.value} emails</p>
          {emails?.percentage?.toString() === "N/A" ? (
            <></>
          ) : (
            <PercentageStatus value={emails?.percentage} isUp={emails?.up} />
          )}
        </div>
      )}
      {phones && (
        <div className="flex gap-2 items-center">
          <div className="w-2 h-2 rounded-full bg-[#1D6FF3]"></div>

          <p className="text-gray-950 text-sm">{phones?.value} calls</p>
          {phones?.percentage?.toString() === "N/A" ? (
            <></>
          ) : (
            <PercentageStatus value={phones?.percentage} isUp={phones?.up} />
          )}
        </div>
      )}
    </div>
  );
};
