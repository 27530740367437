import { TUserOrganisation, TUserOrganisationMember, UserRole } from "@/types";
import { callApi } from "./api.service";

export async function getUserOrganization(): Promise<TUserOrganisation> {
  return new Promise((resolve, reject) => {
    callApi("/users/me/organization", "get", true, {})
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => reject(err));
  });
}

export async function getUserOrganizationMembers(): Promise<
  TUserOrganisationMember[]
> {
  return new Promise((resolve, reject) => {
    callApi("/api/organization/users", "get", true, {})
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => reject(err));
  });
}

export async function postOrganizationName(name: string): Promise<{
  id: number | null;
  name: string;
}> {
  const resp = await callApi(
    `/api/management/manager/create-organization`,
    "post",
    true,
    { name }
  );

  return resp.data;
}

export async function postAddOrganizationEmails(emails: string[]) {
  const resp = await callApi(`/api/management/manager/invite`, "post", true, {
    emails,
  });

  return resp.data;
}

export async function postUpdateUserRole(data: {
  userId: number;
  role: UserRole;
}) {
  const resp = await callApi(
    `/api/management/manager/update-role`,
    "post",
    true,
    data
  );

  return resp.data;
}

export async function deleteUser(userId: number) {
  const resp = await callApi(
    `/api/management/manager/users/${userId}`,
    "delete",
    true,
    {}
  );

  return resp.data;
}

export async function sendInvitationToken(token: string) {
  const resp = await callApi(`/api/invite?token=${token}`, "get", true, {});

  return resp.data;
}
