import { DoughnutChart } from "@/components/doughnut-chart";
import { LoadboardPerformanceItem } from "./LoadboardPerformanceItem";

import { DateRangeTabs } from "./DateRangeTabs";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { getUserPerformanceGraphData } from "@/redux/async/getAnalyticsData";
import { Key, useCallback, useEffect, useMemo } from "react";
import { setPerformancePeriod } from "@/redux/slices/analytics-slice";
import { cn } from "@nextui-org/react";
import { toClockTimeBySeconds } from "@/utils/toClockTime";
import { DoughnutChartData, TAnalyticsPerformanceGraph } from "@/types";
import {
  LOAD_BOARDS_NAME_REGEX,
  LOAD_BOARDS_NAME_MAP,
} from "@/constants/loadboards";

const labels = ["DAT", "Sylectus", "Truckstop"];
const colors = ["#1e40af", "#1d4ed8", "#2563eb", "#3b82f6", "#60a5fa"];

export const LoadboardPerformance = () => {
  const dispatch = useAppDispatch();

  const userId = useAppSelector((state) => state.authReducer.value.userId);

  const period = useAppSelector(
    (state) => state.analyticsReducer.performancePeriod
  );
  const data: TAnalyticsPerformanceGraph = useAppSelector(
    (state) => state.analyticsReducer.performanceGraphData
  );

  const loading = useAppSelector(
    (state) => state.analyticsReducer.performanceGraphLoading
  );

  useEffect(() => {
    dispatch(
      getUserPerformanceGraphData({
        period: period,
        userIds: [userId],
      })
    );
  }, [dispatch, period, userId]);

  const handlePeriodChange = useCallback(
    (value: Key) => {
      dispatch(setPerformancePeriod(value));
    },
    [dispatch]
  );

  const totalTime = useMemo(() => {
    return toClockTimeBySeconds(data.totalTime);
  }, [data.totalTime]);

  const className = useMemo(
    () =>
      cn(
        "w-full h-[300px] px-4 py-3 rounded-lg border border-gray-200 flex gap-4 flex-col relative",
        {
          "opacity-40": loading,
        }
      ),
    [loading]
  );

  const chartData: DoughnutChartData = useMemo(() => {
    return {
      data: {
        labels: labels,
        datasets: [
          {
            label: "Time",
            data: data.performance_list.map((item) => +item.percentage),
            backgroundColor: colors,
          },
        ],
      },
      tooltipData: {
        times: [
          data.performance_list.map((item) => ({
            value: toClockTimeBySeconds(+item.totalTime),
            percentage: +item.inquiries.comparison_with_the_past,
            up: item.inquiries.up,
          })),
        ],
        labels: [
          data.performance_list.map((item) => {
            const loadboard =
              item.loadboard.match(LOAD_BOARDS_NAME_REGEX)?.[0].toLowerCase() ||
              "";

            return {
              value: LOAD_BOARDS_NAME_MAP[loadboard],
            };
          }),
        ],
      },
    };
  }, [data.performance_list]);

  return (
    <div className={className}>
      <div className="w-full flex justify-between items-center">
        <h4 className="text-gray-500 text-sm font-normal flex items-center gap-2">
          Load board performance
        </h4>
        <DateRangeTabs
          selectedKey={period}
          onSelectionChange={handlePeriodChange}
        />
      </div>
      <div className="flex gap-8 h-[calc(100%_-_50px)] justify-between relative">
        <div className="flex justify-center items-center w-52 h-52">
          <DoughnutChart chartData={chartData} />
          <div className="flex flex-col absolute items-center">
            <span>general time</span>
            <span className="text-lg text-gray-950">{totalTime}</span>
          </div>
        </div>
        <div className="w-full h-full overflow-auto flex flex-col gap-2">
          {data.performance_list.length === 0 && (
            <div className="text-center text-gray-500 my-auto">
              No data available
            </div>
          )}
          {data.performance_list.map((item: any, index: any) => (
            <LoadboardPerformanceItem key={index} data={item} />
          ))}
        </div>
      </div>
    </div>
  );
};
