import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  TUserOrganisation,
  TUserOrganisationMember,
  UserInviteStatus,
  UserRole,
} from "@/types/index";
import {
  getUserOrganisationInfo,
  getUserOrganizationMembersList,
  setUserOrganisationMembers,
} from "../async/getMyTeamInfo";

interface InitialState {
  loading: boolean;
  data: TUserOrganisation;
  members: TUserOrganisationMember[];
  filter: {
    search: string;
    status: UserInviteStatus | "all";
    role: UserRole | "all";
  };
}

const initialState = {
  loading: false,
  data: {
    id: null,
    createdAt: "",
    updatedAt: "",
    name: "",
    dotNumber: "",
    ownerId: null,
    features: [],
    limitUsers: null,
  },
  members: [],
  filter: {
    search: "",
    status: "all",
    role: "all",
  },
} as InitialState;

export const myTeamSlice = createSlice({
  name: "myTeam",
  initialState,
  reducers: {
    setMyTeamFilterSearch: (state, action: PayloadAction<string>) => {
      state.filter.search = action.payload;
    },
    setMyTeamFilterRole: (state, action: PayloadAction<UserRole | "all">) => {
      state.filter.role = action.payload;
    },
    setMyTeamFilterStatus: (
      state,
      action: PayloadAction<UserInviteStatus | undefined>
    ) => {
      state.filter.status = action.payload || "all";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserOrganisationInfo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserOrganisationInfo.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
    });
    builder.addCase(getUserOrganisationInfo.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getUserOrganizationMembersList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getUserOrganizationMembersList.fulfilled,
      (state, action) => {
        state.members = action.payload;
        state.loading = false;
      }
    );
    builder.addCase(getUserOrganizationMembersList.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(setUserOrganisationMembers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(setUserOrganisationMembers.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(setUserOrganisationMembers.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  setMyTeamFilterSearch,
  setMyTeamFilterRole,
  setMyTeamFilterStatus,
} = myTeamSlice.actions;

export default myTeamSlice.reducer;
