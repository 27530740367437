import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
} from "@nextui-org/react";
import { ReactComponent as PlusIcon } from "@/assets/icons/plus-icon.svg";
import { MultipleInput } from "./MultipleInput";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import {
  getUserOrganisationInfo,
  getUserOrganizationMembersList,
  setUserOrganisationMembers,
  setUserOrganisationName,
} from "@/redux/async/getMyTeamInfo";

interface InputData {
  id: number;
  value: string;
}

export const AddTeamMember = () => {
  const dispatch = useAppDispatch();
  const [organizationName, setOrganizationName] = useState("");
  const [emailList, setEmailList] = useState<InputData[]>([]);

  const { role } = useAppSelector((state) => state.authReducer.value);
  const { loading, data: organisation } = useAppSelector(
    (state) => state.myTeamReducer
  );

  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const isManager = role === "MANAGER";

  const handleSubmit = async () => {
    if (!organisation.id) {
      await dispatch(setUserOrganisationName(organizationName));
      dispatch(getUserOrganisationInfo());
    } else {
      await dispatch(
        setUserOrganisationMembers(emailList.map((item) => item.value))
      );
      setEmailList([]);
      dispatch(getUserOrganizationMembersList());
      onOpenChange();
    }
  };

  if (!isManager) return null;

  return (
    <>
      <Button
        radius="lg"
        color="primary"
        onPress={onOpen}
        startContent={<PlusIcon />}
      >
        Invite Members
      </Button>
      <Modal size="xl" isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1 text-gray-950 text-2xl pt-6">
                {organisation.id
                  ? "Invite teammates"
                  : "Name your organization"}
              </ModalHeader>
              <ModalBody>
                <p>
                  {organisation.id
                    ? "Enter the email address of the teammates you want to invite to the LoadConnect."
                    : "Enter the name of your organization. It can be your company name or a team name."}
                </p>
                {organisation.id ? (
                  <MultipleInput
                    list={emailList}
                    onChange={setEmailList}
                    pattern={/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i}
                    placeholder="Start typing email"
                    errorMessage="Please enter valid email"
                  />
                ) : (
                  <Input
                    value={organizationName}
                    variant="bordered"
                    onValueChange={setOrganizationName}
                    placeholder="Organization name"
                  />
                )}
              </ModalBody>
              <ModalFooter className="flex justify-between pb-6">
                <Button
                  color="default"
                  variant="bordered"
                  onPress={() => {
                    onClose();
                    setEmailList([]);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  isLoading={loading}
                  color="primary"
                  onPress={handleSubmit}
                  isDisabled={
                    !organisation.id ? !organizationName : !emailList.length
                  }
                >
                  {organisation.id ? "Invite" : "Create"}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};
