import {
  Dropdown,
  DropdownTrigger,
  Button,
  DropdownMenu,
  DropdownItem,
} from "@nextui-org/react";
import { ReactComponent as VerticalDotsIcon } from "@/assets/icons/vertical-dots-icon.svg";
import { TUserOrganisationMember } from "@/types";
import { FC, Key } from "react";
import { deleteUser, postUpdateUserRole } from "@/server/myTeam.service";
import ToastMessage from "@/components/toast";
import { useAppDispatch } from "@/redux/store";
import { getUserOrganizationMembersList } from "@/redux/async/getMyTeamInfo";

interface Props {
  user: TUserOrganisationMember;
}

const ACTION_KEYS = {
  CHANGE_STATUS: "changeStatus",
  DELETE: "delete",
};

export const MyTeamMemberActions: FC<Props> = ({ user }) => {
  const dispatch = useAppDispatch();
  const { userId, role, email } = user;
  const isManager = role === "MANAGER";

  const handleAction = (key: Key) => {
    switch (key) {
      case ACTION_KEYS.CHANGE_STATUS:
        postUpdateUserRole({
          userId: userId as number,
          role: isManager ? "USER" : "MANAGER",
        })
          .then(() => {
            ToastMessage(
              "success",
              `${email} is now ${isManager ? "User" : "Manager"}`
            );
            dispatch(getUserOrganizationMembersList());
          })
          .catch((err) => {
            console.error(err);
            ToastMessage("error", "Something went wrong");
          });
        break;
      case ACTION_KEYS.DELETE:
        deleteUser(userId as number)
          .then(() => {
            ToastMessage(
              "success",
              `${email} is deleted from the organization`
            );
            dispatch(getUserOrganizationMembersList());
          })
          .catch((err) => {
            console.error(err);
            ToastMessage("error", "Something went wrong");
          });
        break;
      default:
        break;
    }
  };

  if (!userId) return <></>;

  return (
    <Dropdown>
      <DropdownTrigger>
        <Button isIconOnly size="sm" variant="light">
          <VerticalDotsIcon className="text-default-300" />
        </Button>
      </DropdownTrigger>
      <DropdownMenu aria-label="User actions" onAction={handleAction}>
        <DropdownItem
          textValue={ACTION_KEYS.CHANGE_STATUS}
          key={ACTION_KEYS.CHANGE_STATUS}
        >
          Set as {isManager ? "User" : "Manager"}
        </DropdownItem>
        <DropdownItem
          className="text-danger"
          color="danger"
          textValue={ACTION_KEYS.DELETE}
          key={ACTION_KEYS.DELETE}
        >
          Delete
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};
