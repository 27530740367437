import { ReactComponent as ArrowIcon } from "@/assets/icons/arrow-status-icon.svg";
import { cn } from "@nextui-org/react";
import { FC } from "react";

interface Props {
  value?: number | string;
  isUp?: boolean;
}

export const PercentageStatus: FC<Props> = ({ value, isUp }) => {
  const hasPercentage = value?.toString().includes("%");

  const colorClass = cn({
    "text-xs flex items-baseline gap-1": true,
    "text-red-500": !isUp,
    "text-green-500": isUp,
  });

  const iconClass = cn({
    "-rotate-180": !isUp,
  });

  return (
    <span className={colorClass}>
      <ArrowIcon className={iconClass} />
      <span>
        {value}
        {!hasPercentage && "%"}
      </span>
    </span>
  );
};
