import { AddTeamMember, MyTeamData } from "@/components/my-team/";
import { useAppSelector } from "@/redux/store";
import { LockedView } from "@/components/my-team/components/LockedView";

export const MyTeam = () => {
  const { features, organizationId } = useAppSelector(
    (state) => state.authReducer.value
  );
  const organization = useAppSelector((state) => state.myTeamReducer.data);
  const isMyTeamAvailable = features.includes("team-management");

  if (!isMyTeamAvailable && !organizationId) {
    return <LockedView />;
  }

  return (
    <div className="flex flex-col gap-4 h-full">
      <div className="flex gap-4 items-center justify-between">
        <h1 className="font-medium text-xl">
          {organization.name || "My Team"}
        </h1>
        <AddTeamMember />
      </div>
      <MyTeamData />
    </div>
  );
};
