import { ReactComponent as BookIcon } from "@/assets/icons/book-icon.svg";
import { ReactComponent as LineChartIcon } from "@/assets/icons/line-chart-icon.svg";
import { ReactComponent as LightningIcon } from "@/assets/icons/lightning-icon.svg";
import { ReactComponent as LightningEmoji } from "@/assets/svg/lightning-emoji.svg";
import { Button, Link } from "@nextui-org/react";

const FEATURES = [
  {
    icon: <BookIcon />,
    title: "Load history",
    description: "Find in archive all your load inquires.",
  },
  {
    icon: <LineChartIcon />,
    title: "Performance review",
    description:
      "Track your and your team's performance daily to not miss nothing.",
  },
  {
    icon: <LightningIcon />,
    title: "Insights",
    description: "Find interesting and important insights about your work.",
  },
];

export const LockedView = () => {
  return (
    <div className="w-full h-full backdrop-blur-xl z-10 -m-10 flex flex-col justify-center items-center gap-6">
      <LightningEmoji />
      <div className="flex flex-col gap-2 items-center max-w-[400px]">
        <h3 className="text-gray-800 text-center">
          Unlock Team Analytics for Your Dispatching Success
        </h3>
        <p className="text-gray-800">
          Fill out the form below, and our team will get in touch to help you
          unlock:
        </p>
      </div>
      <div className="flex flex-col gap-2">
        {FEATURES.map((feature) => (
          <div className="flex gap-3 bg-[#F3F7FE] max-w-[400px] p-3 rounded-lg">
            <div className="flex-shrink-0">{feature.icon}</div>
            <div className="flex flex-col gap-1">
              <p className="text-gray-950 font-medium">{feature.title}</p>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          </div>
        ))}
      </div>
      <Button
        as={Link}
        href="https://try.loadconnect.io/Contact"
        target="_blank"
        color="primary"
      >
        Contact Us
      </Button>
    </div>
  );
};
