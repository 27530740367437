import { setInvitationToken } from "@/redux/slices/auth-slice";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { Progress } from "@nextui-org/progress";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const InvitationPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { urlToken } = useParams();
  const invitationToken = useAppSelector(
    (state) => state.myTeamReducer.invitationToken
  );

  useEffect(() => {
    if (!urlToken || invitationToken) {
      navigate("/");
    } else {
      dispatch(setInvitationToken(urlToken));
      navigate("/");
    }
  }, [dispatch, invitationToken, navigate, urlToken]);

  return (
    <Progress
      size="sm"
      isIndeterminate
      aria-label="Loading..."
      className="max-w-md"
    />
  );
};

export default InvitationPage;
