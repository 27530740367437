import { Input, SharedSelection } from "@nextui-org/react";
import { ReactComponent as SearchIcon } from "@/assets/icons/search-icon.svg";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { setManagerQueriesParams } from "@/redux/slices/analytics-slice";
import { debounce } from "lodash";

export const DispatchersTableFilters = () => {
  const dispatch = useAppDispatch();
  const queryParams = useAppSelector(
    (state) => state.analyticsReducer.managerQueriesParams
  );

  const handleFilterChange = (
    key: string,
    value?: SharedSelection | string
  ) => {
    dispatch(
      setManagerQueriesParams({
        ...queryParams,
        [key]: value,
      })
    );
  };

  const handleSearchQueryChange = debounce((value: string) => {
    handleFilterChange("searchQuery", value);
  }, 1000);

  return (
    <div className="h-10 flex gap-4 justify-between">
      <Input
        isClearable
        placeholder="Search"
        variant="bordered"
        className="w-full max-w-80"
        startContent={<SearchIcon />}
        onValueChange={handleSearchQueryChange}
      />
    </div>
  );
};
