import { ChartOptions } from "chart.js";
import { useState, useMemo, FC } from "react";
import { Bar } from "react-chartjs-2";
import { ChartTooltip } from "../chart-tooltip";
import { BarChartData, TooltipModel } from "@/types";

interface Props {
  chartData: BarChartData;
}

export const BarChart: FC<Props> = ({ chartData }) => {
  const [tooltipModel, setTooltipModel] = useState<TooltipModel | null>(null);

  const options: ChartOptions<"bar"> = useMemo(
    () => ({
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          external: function ({ tooltip }) {
            if (tooltip.opacity === 0) {
              setTooltipModel(null);
            } else {
              setTooltipModel({
                dataIndex: tooltip.dataPoints[0].dataIndex,
                datasetIndex: tooltip.dataPoints[0].datasetIndex,
                caretX: tooltip.caretX,
                caretY: tooltip.caretY,
              });
            }
          },
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          grid: {
            offset: true,
          },
        },
        y: {
          ticks: {
            stepSize: 1,
          },
          min: 0,
          max: 24,
          beginAtZero: true,
          title: {
            display: true,
            text: "Hours",
          },
        },
      },
    }),
    []
  );

  return (
    <div className="relative w-full h-full">
      <Bar data={chartData.data} options={options} />
      <ChartTooltip
        withShift
        withArrow
        tooltipModel={tooltipModel}
        data={chartData.tooltipData}
      />
    </div>
  );
};
