import cube from "@cubejs-client/core";
import {
  TAnalyticsData,
  TAnalyticsLoadboardTimeGraph,
  TAnalyticsPerformanceGraph,
  TAnalyticsPeriod,
} from "@/types";
import {
  accumulatePeriodToDateRange,
  acummulateDates,
  queryAVGPostToContactTimeAnalytics,
  queryAVGRateAnalytics,
  queryDispatchLoadbord,
  queryInquiriesAnalytics,
  queryInquiriesByLoadboards,
  queryLoadBoardTimeGraphAnalytics,
  queryPerformanceByLoadboards,
  queryProductiveTimeAnalytics,
  queryUserEmailAnalytics,
  queryUserPhoneAnalytics,
  transformAnalytics,
  transformDispatchLeaderBoard,
  transformInquiriesByLoadboard,
  transformTimeGraph,
} from "./cube.service-helper";

const cubeApi = cube(
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MzUyNzYwMTN9.9Juro0MRGPLqqVAkxlaHOjs6uCk7udaosYnXB0li8Mc",
  {
    apiUrl: "https://gross-lizard.aws-us-east-1.cubecloudapp.dev/cubejs-api/v1",
  }
);

//user
export async function getCubeUserPhoneAnalyticsData(
  period: TAnalyticsPeriod,
  userIds: string[]
): Promise<TAnalyticsData> {
  const measure = "load_event_table.count";
  const { granularity, dateRange } = acummulateDates(period);
  const resultSet = await cubeApi.load(
    queryUserPhoneAnalytics(granularity, dateRange, measure, userIds)
  );
  const data = resultSet.tablePivot({
    x: ["x"],
    y: ["measures"],
    fillMissingDates: true,
  });
  return transformAnalytics(data, measure);
}

export async function getCubeUserEmailAnalyticsData(
  period: TAnalyticsPeriod,
  userIds: string[]
): Promise<TAnalyticsData> {
  const measure = "load_event_table.count";
  const { granularity, dateRange } = acummulateDates(period);
  const resultSet = await cubeApi.load(
    queryUserEmailAnalytics(granularity, dateRange, measure, userIds)
  );
  const data = resultSet.tablePivot({
    x: ["x"],
    y: ["measures"],
    fillMissingDates: true,
  });
  return transformAnalytics(data, measure);
}

export async function getCubeUserAVGRateAnalyticsData(
  period: TAnalyticsPeriod,
  userIds: string[]
): Promise<TAnalyticsData> {
  const measure = "load_event_table.avg_rate";
  const { granularity, dateRange } = acummulateDates(period);
  const resultSet = await cubeApi.load(
    queryAVGRateAnalytics(granularity, dateRange, measure, userIds)
  );
  const data = resultSet.tablePivot({
    x: ["x"],
    y: ["measures"],
    fillMissingDates: true,
  });
  return transformAnalytics(data, measure);
}

export async function getCubeUserAVGPostToContactTimeAnalyticsData(
  period: TAnalyticsPeriod,
  userIds: string[]
): Promise<TAnalyticsData> {
  const measure = "load_event_table.avg_post_to_contact_time";
  const { granularity, dateRange } = acummulateDates(period);
  const resultSet = await cubeApi.load(
    queryAVGPostToContactTimeAnalytics(granularity, dateRange, measure, userIds)
  );
  const data = resultSet.tablePivot({
    x: ["x"],
    y: ["measures"],
    fillMissingDates: true,
  });

  const transformedData = transformAnalytics(data, measure);
  if (transformedData.total !== "0") transformedData.up = !transformedData.up;
  return transformedData;
}

export async function getCubeUserProductiveTimeAnalyticsData(
  period: TAnalyticsPeriod,
  userIds: string[]
): Promise<TAnalyticsData> {
  const measure = "user_session.sum_active_seconds";
  const { granularity, dateRange } = acummulateDates(period);
  const resultSet = await cubeApi.load(
    queryProductiveTimeAnalytics(granularity, dateRange, measure, userIds)
  );
  const data = resultSet.tablePivot({
    x: ["x"],
    y: ["measures"],
    fillMissingDates: true,
  });
  const transformedData = transformAnalytics(data, measure);
  if (transformedData.total !== "0") transformedData.up = !transformedData.up;
  return transformedData;
}

export async function getCubeInquiriesAnalytics(
  period: TAnalyticsPeriod,
  userIds: string[]
): Promise<any[]> {
  const measure = "load_event_table.count";
  let dateRange = "this week";
  if (period === "month") {
    dateRange = "this month";
  }
  const resultSet = await cubeApi.load(
    queryInquiriesAnalytics(dateRange, measure, userIds)
  );
  const data = resultSet.tablePivot({
    x: ["load_event_table.contact_time.day"],
    y: [
      "load_event_table.user_id",
      "measures",
      "load_event_table.type_of_contact",
    ],
    fillMissingDates: true,
  });
  return data;
}

export async function getCubeTimeOnLoadBoard(
  period: TAnalyticsPeriod,
  userIds: string[]
): Promise<any> {
  const measure = "user_session.sum_active_seconds";
  let dateRange = "this week";
  if (period === "month") {
    dateRange = "this month";
  }
  const resultSet = await cubeApi.load(
    queryLoadBoardTimeGraphAnalytics(dateRange, measure, userIds)
  );
  const data = resultSet.tablePivot({
    x: ["x"],
    y: ["user_session.user_id", "measures"],
    fillMissingDates: true,
  });
  return data;
}

export async function getCubeTimeOnLoadBoardWithInquiriesAnalytics(
  period: TAnalyticsPeriod,
  userIds: string[]
): Promise<TAnalyticsLoadboardTimeGraph[]> {
  const inquiriesData = await getCubeInquiriesAnalytics(period, userIds);
  const timeOnloadBoards = await getCubeTimeOnLoadBoard(period, userIds);
  const newTimeOnloadboards = [];
  const newInquiriesData = [];

  for (let userId of userIds) {
    const recordsOfUserId = [];
    const inquiriesOfUserId = [];

    for (let timeOnLoad of timeOnloadBoards) {
      const common: any = {};
      common["user_session.sum_active_seconds"] = timeOnLoad[
        userId + ",user_session.sum_active_seconds"
      ]
        ? timeOnLoad[userId + ",user_session.sum_active_seconds"]
        : 0;
      common["user_session.start_time.day"] =
        timeOnLoad["user_session.start_time.day"];
      recordsOfUserId.push(common);
    }
    newTimeOnloadboards.push({ userId: userId, data: recordsOfUserId });

    for (let inquiry of inquiriesData) {
      const common: any = {};
      common["EMAIL"] = inquiry[userId + ",EMAIL,load_event_table.count"]
        ? inquiry[userId + ",EMAIL,load_event_table.count"]
        : 0;
      common["PHONE"] = inquiry[userId + ",PHONE,load_event_table.count"]
        ? inquiry[userId + ",PHONE,load_event_table.count"]
        : 0;
      common["load_event_table.contact_time.day"] =
        inquiry["load_event_table.contact_time.day"];
      inquiriesOfUserId.push(common);
    }
    newInquiriesData.push({ userId: userId, data: inquiriesOfUserId });
  }
  const aggregateData: TAnalyticsLoadboardTimeGraph[] = [];

  for (var i = 0; i < userIds.length; i++) {
    aggregateData.push(
      transformTimeGraph(
        newInquiriesData[i].data,
        newTimeOnloadboards[i].data,
        userIds[i]
      )
    );
  }
  return aggregateData;
}

export async function getCubePerformanceGraph(
  period: TAnalyticsPeriod,
  userIds: string[]
): Promise<any> {
  const measure = "user_session.sum_active_seconds";
  const query = queryPerformanceByLoadboards(
    accumulatePeriodToDateRange(period),
    measure,
    userIds
  );
  const resultSet = await cubeApi.load(query);

  const data = resultSet.tablePivot({
    x: ["user_session.start_time.day"],
    y: ["user_session.source", "measures"],
    fillMissingDates: true,
  });
  return JSON.parse(
    JSON.stringify(data).replaceAll(",user_session.sum_active_seconds", "")
  );
}

export async function getCubeInquiriesByLoadboard(
  period: TAnalyticsPeriod,
  userIds: string[]
) {
  const measure = "load_event_table.count";
  const { granularity, dateRange } = acummulateDates(period);
  const query = queryInquiriesByLoadboards(
    granularity,
    dateRange,
    measure,
    userIds
  );
  const resultSet = await cubeApi.load(query);
  const data = resultSet.tablePivot({
    x: ["load_event_table.contact_time.day"],
    y: ["load_event_table.source", "measures"],
    fillMissingDates: true,
  });
  return transformInquiriesByLoadboard(data, measure);
}

export async function getCubePerformanceGraphWithInquiries(
  period: TAnalyticsPeriod,
  userIds: string[]
): Promise<TAnalyticsPerformanceGraph> {
  const performanceByLoadboard = await getCubePerformanceGraph(period, userIds);
  const inquiriesByLoadBoard = await getCubeInquiriesByLoadboard(
    period,
    userIds
  );
  let totalTime = 0;
  const aggregatedData: any[] = [];
  if (performanceByLoadboard.length > 0)
    for (const [key, value] of Object.entries(performanceByLoadboard[0])) {
      aggregatedData.push({
        loadboard: key,
        inquiries: inquiriesByLoadBoard[key]
          ? inquiriesByLoadBoard[key]
          : {
              percentage: 0,
            },
        totalTime: value,
      });
      totalTime += Number(value);
    }
  aggregatedData.forEach((element) => {
    if (element.totalTime > 0)
      element.percentage = ((element.totalTime * 100) / totalTime).toFixed(2);
    else element.percentage = 0;
  });
  return {
    totalTime: totalTime,
    performance_list: aggregatedData,
  };
}

export async function getCubeDispatchLoadboard(
  period: TAnalyticsPeriod,
  userIds: string[]
) {
  const { granularity, dateRange } = acummulateDates(period);
  const query = queryDispatchLoadbord(granularity, dateRange, userIds);
  const resultSet = await cubeApi.load(query);
  const data = resultSet.tablePivot({
    x: ["load_event_table.contact_time.day"],
    y: [
      "load_event_table.user_id",
      "load_event_table.type_of_contact",
      "measures",
    ],
    fillMissingDates: true,
  });

  return transformDispatchLeaderBoard(period, data, userIds);
}
