import { Chip, Input, InputProps, Kbd } from "@nextui-org/react";
import { FC, KeyboardEvent, useState } from "react";

interface ListItem {
  id: number;
  value: string;
}

interface Props extends Omit<InputProps, "onChange" | "pattern" | "list"> {
  list: ListItem[];
  onChange: (item: ListItem[]) => void;
  pattern?: RegExp;
}

export const MultipleInput: FC<Props> = ({
  list,
  onChange,
  pattern,
  errorMessage,
  placeholder,
}) => {
  const [value, setValue] = useState("");
  const [isInvalid, setIsInvalid] = useState(false);

  const handleAddItem = (e: KeyboardEvent) => {
    if (e.key !== "Enter") return;
    const target = e.target as HTMLInputElement;
    const isValid = validate(target.value);

    if (!isValid) {
      setIsInvalid(true);
      return;
    }

    onChange([...list, { id: Date.now(), value: target.value }]);
    setValue("");
  };

  const handleChange = (value: string) => {
    setValue(value);
    setIsInvalid(false);
  };

  const handleRemoveItem = (id: number) => {
    onChange(list.filter((item) => item.id !== id));
  };

  const clearList = () => {
    onChange([]);
    setValue("");
  };

  const validate = (value: string) => {
    if (!pattern) return true;
    return pattern.test(value);
  };

  return (
    <div>
      <div className="flex mb-4 flex-wrap w-full gap-2">
        {list.map(({ id, value }) => (
          <Chip
            onClose={() => handleRemoveItem(id)}
            key={id}
            color="primary"
            size="lg"
            variant="flat"
            radius="lg"
            classNames={{
              content: "max-w-44 truncate",
            }}
          >
            {value}
          </Chip>
        ))}
        {list.length > 1 && (
          <Chip
            onClick={clearList}
            color="danger"
            size="lg"
            variant="light"
            radius="lg"
            className="cursor-pointer"
          >
            Clear All
          </Chip>
        )}
      </div>
      <Input
        onKeyUp={handleAddItem}
        variant="bordered"
        placeholder={placeholder}
        value={value}
        onValueChange={handleChange}
        errorMessage={errorMessage}
        isInvalid={isInvalid}
        description={
          <p className="flex gap-1 items-center">
            Write email and press{" "}
            <Kbd
              classNames={{
                content: "text-xs",
              }}
              keys={["enter"]}
            >
              Enter
            </Kbd>{" "}
            to add
          </p>
        }
      />
    </div>
  );
};
